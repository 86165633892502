<template>
  <div
      class="multiMenu"
      v-click-outside="closeSubmenu"
  >
    <div class="multiMenu__cta ml-0">
      <a :href="homePath()" class="home-icon">
        <svg fill="#202125" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="32px" height="32px"
             style="margin: 18px">
          <path
              d="M 8 1.320313 L 0.660156 8.132813 L 1.339844 8.867188 L 2 8.253906 L 2 14 L 7 14 L 7 9 L 9 9 L 9 14 L 14 14 L 14 8.253906 L 14.660156 8.867188 L 15.339844 8.132813 Z M 8 2.679688 L 13 7.328125 L 13 13 L 10 13 L 10 8 L 6 8 L 6 13 L 3 13 L 3 7.328125 Z"
              fill="#ffffff"/>
        </svg>
      </a>
      <div
          class="multiMenu__cta"
          @click.prevent="toggleMainMenu"
          v-if="!isMobile"
      >
        <div class="multiMenu__cta--iconmenu">
          <button
              :class="{ 'is-active': isSubMenuActive }"
              class="hamburger hamburger--spin"
              type="button"
          >
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </button>
        </div>
        <div class="multiMenu__cta--text">Menu</div>
      </div>
    </div>
    <nav
        class="multiMenu__wrapper"
        :class="isSubMenuActive || isMobile ? 'active' : ''"
    >
      <!-- DEBUT LEVEL 1 -->
      <ul class="multiMenu__wrapper--level1">
        <li
            v-for="(itemLvl1, index) in getSiteMap.child_pages"
            :key="index"
            class=""
        >
          <span
              @click.prevent="submenuIndex = index"
              class="
              multiMenu__wrapper--item-lvl1 multiMenu__wrapper--subtitle-lvl1
            "
              :class="submenuIndex === index ? 'active' : ''"
          >
            {{ itemLvl1.title }} <icon-arrow/>
          </span>
        </li>
      </ul>
      <!-- FIN LEVEL 1 -->
      <!-- DEBUT LEVEL 2 -->
      <div v-if="submenuIndex != null" class="multiMenu__wrapper--level2">
        <span
            class="multiMenu__wrapper--back-to"
            v-if="isMobile"
            @click="submenuIndex = null"
        >
          &#60; {{ $t('generalUi.goBack') }}
        </span>
        <ul
            v-for="(itemLvl2, index) in getSiteMap.child_pages"
            :key="index"
            v-show="index === submenuIndex"
            class="multiMenu__wrapper--lvl2-wrapper"
        >
          <router-link
              :to="itemLvl2.full_url"
              class="multiMenu__wrapper--big-subtitle">
            {{ itemLvl2.title }}
          </router-link>

          <li
              v-for="(itemLvl3, index) in itemLvl2.child_pages"
              :key="index"
              class="multiMenu__wrapper--item-lvl2"
          >
            <!-- DEBUT LEVEL 3 -->
            <ul>
              <li
                  class="multiMenu__wrapper--item-lvl3"
              >
                <router-link :to="itemLvl3.full_url">
                  {{ itemLvl3.title }}
                </router-link>
              </li>
              <li
                  v-for="(itemLvl3bis, index) in itemLvl3.child_pages"
                  :key="index"
                  class="multiMenu__wrapper--item-lvl3"
              >
                <router-link :to="itemLvl3bis.full_url">
                  {{ itemLvl3bis.title }}
                </router-link>
              </li>
            </ul>
            <!-- FIN LEVEL 3 -->
          </li>
        </ul>
        <!-- FIN LEVEL 2 -->
      </div>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ClickOutside from 'vue-click-outside'
import IconArrow from '../../icons/IconArrow.vue'
import { SITE_INFO_SAX } from '../../../router/configSites'

export default {
  components: {
    IconArrow
  },
  props: {
    /**
     * Permet de modifier le markup pour la version tablet mobile
     */
    isMobile: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isSubMenuActive: false,
      submenuIndex: 0
    }
  },
  computed: mapGetters(['getSiteMap']),
  methods: {
    /**
     * Set the burger menu state
     * @returns {Void}
     */
    toggleMainMenu () {
      this.isSubMenuActive = !this.isSubMenuActive
      this.submenuIndex = 0
    },
    /**
     * Methode uniquement pour le click outside
     */
    closeSubmenu () {
      this.isSubMenuActive = false
      this.submenuIndex = null
    },
    homePath () {
      if (this.$store.getters.getLang === 'fr-CA') {
        if (this.$store.SITE_ID === SITE_INFO_SAX.id) {
          return `/fr/${SITE_INFO_SAX.slugFr}`
        }
      } else if (this.$store.SITE_ID === SITE_INFO_SAX.id) {
        return `/en/${SITE_INFO_SAX.slugEn}`
      }
      return '/'
    }
  },
  directives: {
    ClickOutside
  }
}
</script>

<style lang="scss" scoped>
.multiMenu {
  &__cta {
    display: flex;
    align-items: center;
    padding: 0 2.5vw;
    cursor: pointer;

    &--text {
      color: $white;
      margin-left: 2rem;
      font-size: 1.6rem;
    }
  }

  &__wrapper {
    opacity: 0;
    transform: translateY(-10px);
    pointer-events: none;
    transition: all 0.3s ease-out;

    @include breakpoint("lg") {
      position: absolute;
      top: 100%;
      width: calc(100vw - 16.6666666667vw);
      display: flex;
    }

    &.active {
      pointer-events: initial;
      opacity: 1;
      transform: translateY(0px);
    }

    ul,
    li {
      list-style: none;
      margin: 0;
    }

    &--level1 {
      background-color: $grey-light;
      cursor: pointer;
      @include breakpoint("lg") {
        padding-top: 2.5rem;
        min-width: 350px;
      }
    }

    &--level2 {
      padding: 2rem 2.5rem 2.5rem 2.5vw;
      background-color: $grey-b2b;
      flex: 1 1 auto;
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;

      @include breakpoint("lg") {
        padding: 3.5rem 2.5rem 2.5rem 2.5vw;
        justify-content: space-between;
        position: relative;
        top: initial;
        height: auto;
        width: auto;
      }
    }

    &--lvl2-wrapper {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }

    &--item-lvl1 {
      padding: 1.5rem 1.5rem 1.5rem 2.5vw;
      height: 7rem;
      margin: 0;
      font-size: 1.4rem;
      font-weight: 600;
      transition: all 0.3s ease;

      @include breakpoint("lg") {
        height: auto;
      }

      .icon {
        opacity: 0;
        transition: all 0.1s ease;
        display: none;
        @include breakpoint("lg") {
          display: block;
        }
      }

      &:hover,
      &.active {
        background-color: $dark-grey-b2b;
        color: $white;
        text-decoration: none;

        .icon {
          opacity: 1;
        }
      }
    }

    &--subtitle-lvl1 {
      display: flex;
      justify-content: center;
      align-items: center;

      @include breakpoint("lg") {
        justify-content: space-between;
      }

      svg {
        width: 1.4rem;
      }
    }

    &--big-subtitle {
      font-size: 1.8rem;
      padding-bottom: 1rem;
      margin-bottom: 2rem;
      border-bottom: 1px solid $grey-light;
      display: block;
      font-weight: 600;
      flex: 2 2 100%;
    }

    &--item-lvl1 {
      padding: 1.5rem 1.5rem 1.5rem 2.5vw;
      margin: 0;
      font-size: 1.4rem;
      font-weight: 600;

      &:hover {
        background-color: $dark-grey-b2b;
        color: $white;
      }
    }

    &--item-lvl2 {
      flex: 0 1 100%;
      padding-bottom: 3rem;

      @include breakpoint("md") {
        padding-bottom: 0;
        flex: 0 1 32%;

        &:nth-child(n + 5) {
          margin-top: 4rem;
        }
      }
    }

    &--item-lvl3 {
      font-size: 1.2rem;

      &:first-child {
        font-size: 1.4rem;
        font-weight: 600;
        margin-bottom: 1.5rem;
      }
    }

    &--back-to {
      display: block;
      margin-bottom: 2rem;
      cursor: pointer;
      font-weight: 600;
    }
  }
}

.home-icon {
  display: none;
  @media only screen and (min-width: 1025px) {
    display: inline-block;
  }
}
</style>
