export default {
  /**
   * Vue-meta: set the metadata
   * @returns {Object}
   */
  metaInfo () {
    return {
      title: this.metaData.title,
      link: [
        { rel: 'canonical', href: this.metaData.canonicalUrl }
      ],
      meta: [
        {
          name: 'description',
          content: this.metaData.description
        },
        {
          name: 'keywords',
          content: this.metaData.keywords
        },
        // OpenGraph data (Most widely used)
        { property: 'og:title', content: this.metaData.ogTitle ? this.metaData.ogTitle : this.metaData.title },
        { property: 'og:site_name', content: 'Unipro' },
        // The list of types is available here: http://ogp.me/#types
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: this.metaData.canonicalUrl },
        { property: 'og:image', content: this.metaData.ogImgUrl },
        { property: 'og:description', content: this.metaData.description }
      ]
    }
  },
  methods: {
    /**
     * Set the default metadata
     * @param {Object} basicMetas
     * @returns {Void}
     */
    setBasicMeta (basicMetas) {
      // if we haven't metadata
      if (!basicMetas) {
        // stop
        return
      }

      // set the basic metadata
      this.metaData.title = basicMetas.page_title
      this.metaData.description = this.$i18n.t('metaInfo.meta.description')
      this.metaData.ogTitle = basicMetas.page_title
    },
    /**
     * Set the metadata for the page
     * @param {Object} metaData
     * @returns {Void}
     */
    setMeta (metaData) {
      this.metaData.title = metaData.meta_title
        ? metaData.meta_title : this.page[0].page_title
      this.metaData.description = metaData.meta_description
        ? metaData.meta_description : this.$i18n.t('metaInfo.meta.description')
      this.metaData.keywords = metaData.meta_keywords
        ? metaData.meta_keywords : ''
      this.metaData.ogTitle = metaData.meta_og_title
        ? metaData.meta_og_title : this.page.title // eslint-disable-next-line
      this.metaData.ogImgUrl = metaData.meta_image 
        ? (metaData.meta_image.file.url ? metaData.meta_image.file.url : '') : '/img/btn-bumper@2x.png'
    }
  }
}
