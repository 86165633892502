<template>
  <div class="header_wrapper">
    <header>
      <nav class="row no-gutters">
        <div class="brand-nav col-2">
          <router-link :to="getPathUrl()">
            <img src="../../../../public/img/logo.png" alt="UniPro" />
          </router-link>
        </div>

        <div class="primary-nav col">
          <div class="primary-nav__top">
            <span
              v-for="top in topNavigation"
              v-bind:key="top.entity_id"
            >
              <b-link
                v-if="!top.pdf"
                :to="`${getPathUrl()}/${top.slug}`"
              >
                {{ top.page_title }}
              </b-link>
              <b-link
                v-else-if="top.pdf && top.pdf.file && top.pdf.file.url"
                :href="top.pdf.file.url"
                target="_blank"
              >
                {{ top.page_title }}
              </b-link>
            </span>
            <a
              :href="getPdfGenerationUrl"
              class="fill-white"
              target="_blank"
            >
              <IconPrint />
            </a>
            <b-link href="#" class="lang-switcher" @click="changeLang" >
              {{ $t('header.lang') }}
            </b-link>
          </div>
          <div class="primary-nav__bottom">
            <multi-level-menu />
            <AlgoliaSearch siteID="7" />
          </div>
        </div>
      </nav>
    </header>
  </div>
</template>

<script>
import MultiLevelMenu from '../menu/MultiLevelMenu.vue'
import headerMixing from '../../../mixins/header'
import AlgoliaSearch from '../../common/AlgoliaSearch.vue'
import IconPrint from '../../icons/IconPrint.vue'

export default {
  name: 'Header',
  mixins: [headerMixing],
  components: {
    AlgoliaSearch,
    MultiLevelMenu,
    IconPrint
  }
}
</script>

<style lang="scss" scoped>
.header_wrapper{
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9;
}
header {
  position: relative;
  &:after{
    content: '';
    width: 100vw;
    height: 1px;
    background-color: $black;
    background-blend-mode: multiply;
    opacity: 0.2;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  nav {
    min-height: 100px;
  }
}

.brand-nav {
  background: $dark-grey-unipro;
  background: radial-gradient(circle, rgba(53,53,56,1) 0%, rgba(25,24,25,1) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    width: 70%;
  }
  svg {
    fill: $white;
    max-height: 70px;
  }
}
.primary-nav {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;

  a {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    background-color: inherit;
    text-align: center;
    padding: 10px;
    line-height: 1.1;

    transition: all 0.3s ease;
  }

  &__top {
    background-color: $dark-grey-unipro;
    height: 30px;
    border: solid 1px rgba(31, 27, 30, 0.15);
    display: flex;
    justify-content: flex-end;

    a {
      padding: 0 1.5rem;
      color: $white;

      &:hover {
        color: $white;
        text-decoration: underline;
      }

      &.lang-switcher {
        padding: 0 3.6rem 0 1.2rem;
        border-left: 1px solid rgba(31, 27, 30, 0.15);
      }
    }
  }
  &__bottom {
    background-color: $dark-red;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: inherit;

    a {
      flex: 0 1 8.3333333333vw;
      color: $white;
      background-color: inherit;
      transition: all 0.3s ease;
      font-size: 1.3rem;

      &:hover,
      &.router-link-active{
        background-color: $menu-active;
      }
    }
  }
}

.store-nav {
  background-color: $dark-red;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  label {
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 0;
    line-height: 1.1;
    font-size: 1.2rem;
  }

  a {
    display: flex;
    text-decoration: none;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.2rem;

    transition: all 0.3s ease;

    @include breakpoint('xl') {
      padding: 0 2.5rem;
    }

    > * {
      cursor: pointer;
    }
  }

  .cta-find-store {
    background-color: $dark-grey-unipro;
    height: 30px;
    color: $white;

    svg path{
      fill: $white;
    }
  }
  .cta-my-store {
    background-color: $white;
    height: 70px;
    address {
      font-size: 0.9rem;
      margin-bottom: 0;
      line-height: 1.22;
      font-weight: 600;
    }
    .svg-store{
        transform: scale(1.2);
    }
    svg {
      fill: #BDBAC4;
      path{
        fill: #BDBAC4;
      }
    }
  }
}
.login-nav {
  background-color: $dark-grey-unipro;
  color: $white;

  display: flex;
  align-items: stretch;
  justify-content: center;
  align-content: center;

  a {
    color: $white;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    background-color: inherit;

    transition: all 0.3s ease;

    &:hover {
      background-color: $menu-hover;
    }
  }
}

.icon-group {
  height: 100%;
  svg {
    margin-right: 0.8rem;
    &:last-child {
      margin-right: 0;
    }
  }
}

.navbar-brand {
  width: 10%;
}
.nav-brand-img {
  width: 90%;
}

.search-container {
  position: relative;
  overflow: visible;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6.3333333333vw;

  .__input-wrapper{
    input{
      position: absolute;
      top:0;
      right: 0;
      width: 0vw;
      height: 100%;
      border: none;
      border-radius: 0;
      transition: all 0.2s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      pointer-events: none;
      padding: 0;
    }
  }
  .__cta-search{
    color: white;
    position: relative;
    z-index: 2;
    cursor: pointer;
  }
  &.active{
    .__input-wrapper{
      input{
        width: 39.666666665vw;
        pointer-events: auto;
        padding: 20px 40px;
        font-size: 2.1rem;
      }
    }
    .__cta-search{
      color: black;
      cursor: pointer;
    }
  }
}
.search-results {
  background-color: $grey-b2b;
  padding-top: 8rem;
  padding-bottom: 8rem;
  max-height: calc(100vh - 100px);
  overflow: auto;

  &__item{
    margin-bottom: 3rem;
    em{
      font-size: 2.1rem;
      font-style: normal;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
}
</style>
