<template>
  <main id="app">
    <router-view></router-view>
  </main>
</template>

<script>
import objectFitImages from 'object-fit-images'
import axios from 'axios'
import './styles/main.scss'
import Redirection from './router/redirection'
import JsonFilesSys from './utils/JsonFilesSys'

export default {
  name: 'app',
  /**
   * Set the metadata
   * @returns {Object}
   */
  metaInfo () {
    return {
      title: this.$i18n.t('metaInfo.title'),
      titleTemplate: `%s ${this.$i18n.t('metaInfo.titleTemplate')}`,
      htmlAttrs: {
        lang: this.$i18n.t('metaInfo.htmlAttrs.lang'),
        amp: undefined
      },
      headAttrs: {
        test: true
      },
      meta: [
        {
          name: 'description',
          content: this.$i18n.t('metaInfo.meta.description')
        }
      ]
    }
  },
  watch: {
    /**
     * Watch when the route change
     * @returns {Void}
     */
    $route (to, from) {
      // after 3 seconds
      setTimeout(() => {
        // apply the object fit for all images
        this.objectFitImages()
      }, 3000)
    }
  },
  computed: {
    getDataDomainScript () {
      if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging') {
        return '65413eb9-030e-4d52-81f2-3b86f60c67e2-test'
      }

      return '65413eb9-030e-4d52-81f2-3b86f60c67e2'
    }
  },
  methods: {
    /**
     * injecton du script cookiepro adequat suivant la lang / env
     * @returns {Void}
     */
    getCookiePro () {
      const cookieProScript = document.createElement('script')
      cookieProScript.setAttribute('src', `https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js?cachebuster=${new Date().getTime()}`)
      cookieProScript.setAttribute('data-domain-script', this.getDataDomainScript)
      cookieProScript.setAttribute('id', 'cookiepro_script')
      cookieProScript.setAttribute('async', '')
      cookieProScript.setAttribute('data-language', this.$store.getters.getLang === 'fr-CA' ? 'fr' : 'en')
      document.head.appendChild(cookieProScript)
    },
    // removeCookiePro () {
    //   // const getOldCookieProBanner = document.getElementById('onetrust-consent-sdk')
    //   const getOldCookieProScript = document.getElementById('cookiepro_script')

    //   // getOldCookieProBanner.remove()
    //   getOldCookieProScript.remove()
    //   setTimeout(() => {
    //     this.getCookiePro()
    //   }, 2300)
    // },
    /**
     * Event on all click on the page
     * @returns {Void}
     */
    globalClick () {
      // Add global event click on the page
      window.addEventListener('click', (e) => {
        // if we click on the link, and the link is the actual page
        if (e.target.href === window.location.href) {
          // scroll at the top of page
          window.scrollTo({ top: 0, behavior: 'smooth' })
        }
      })
    },
    /**
     * Set the global size for the site
     * @returns {Void}
     */
    vhRoot () {
      // create the default ratio ratio value
      let vhRoot = window.innerHeight * 0.01
      // set the default size
      document.documentElement.style.setProperty('--vh', `${vhRoot}px`)

      // Add global event when the page is resize
      window.addEventListener('resize', () => {
        // We execute the same script as before
        vhRoot = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vhRoot}px`)
      })
    },
    /**
     * Apply the object fit on all images with class o-object-fit
     * @returns {Void}
     */
    objectFitImages () {
      const imagesObjectFit = document.querySelectorAll('.o-object-fit')
      objectFitImages(imagesObjectFit, { watchMQ: true })
    }
  },
  beforeCreate () {
    /**
     * Local function to check if we redirect the user
     * @returns {Void}
     */
    const redirection = () => {
      // loop on all redirection file
      Redirection.forEach((redirect) => {
        // if the current route match with the redirection route
        if (redirect.from === this.$route.fullPath && redirect.from !== redirect.to) {
          // redirect to the new page
          window.location.href = `${redirect.to}`
        }
      })
    }
    // apply the redirections
    redirection()
    // split the current url
    const url = this.$route.path.split('/')
    // If in root and route not finish by /
    if (url.length === 2 && this.$route.path !== '/') {
      // Force it
      window.location.href = `${this.$route.path}/`
      return
    }

    // If is first visite and the lang is not set
    if (url && url[1] && url[1] === 'en' && this.$store.getters.getPublicIp === null) {
      // Set the lang forced
      this.$store.dispatch('setLang', { lang: 'en-CA' })
      this.$i18n.locale = 'en-CA'
      this.$store.dispatch('setMyLangIsDefined', { langIsDefined: true })
    }
    // If is first visite and the lang is not set
    if (url && url[1] && url[1] === 'fr' && this.$store.getters.getPublicIp === null) {
      // Set the lang forced
      this.$store.dispatch('setLang', { lang: 'fr-CA' })
      this.$i18n.locale = 'fr-CA'
      this.$store.dispatch('setMyLangIsDefined', { langIsDefined: true })
    }

    // if the user is not tracking by ip
    if (this.$store.getters.getPublicIp === null) this.$store.dispatch('setPublicIp')

    /**
     * Local function to dispatch the store, shop and services files on the store
     * @param {String} file
     * @param {Object} item
     * @param {String} key
     * @param {String} dispatch
     * @returns {Void}
     */
    const setJsonFiles = (file, item, key, dispatch) => {
      // get the json file
      axios
        .get(`${JsonFilesSys(file)}`)
        .then((response) => {
          // format the data
          item[key] = response.data
          // save the data in the store
          this.$store.dispatch(dispatch, item)
        })
        // eslint-disable-next-line handle-callback-err
        .catch((error) => {
          // display error on the console
          console.log('[JSON_FILE_ERROR]', error) // eslint-disable-line
          // set the empty item in the store
          this.$store.dispatch(dispatch, item)
        })
    }

    // if the shops is not set in the local store
    if (this.$store.getters.getShops.length === 0) {
      setJsonFiles('shop_site_6_all_yes.json', { shops: [] }, 'shops', 'setShops')
    }

    // if the stores is not set in the local store
    if (this.$store.getters.getStores.length === 0) {
      setJsonFiles('store_site_6_all_yes.json', { stores: [] }, 'stores', 'setStores')
    }

    // if the services is not set in the local store
    if (this.$store.getters.getServices.length === 0) {
      setJsonFiles('service_site_6_all_yes.json', { services: [] }, 'services', 'setServices')
    }

    if (this.$store.SITE_ID === 7) {
      const siteMapFile = `site_map_${this.$store.SITE_ID}_${this.$store.getters.getLang}.json`
      setJsonFiles(siteMapFile, { sitemap: [] }, 'sitemap', 'setSiteMap')
    }

    // If we force lang by url
    if (url[1] === 'en' && this.$store.getters.getLang === 'fr-CA') {
      // Set the lang forced
      this.$store.dispatch('setLang', { lang: 'en-CA' })
      this.$i18n.locale = 'en-CA'
    }
    // If we force lang by url
    if (url[1] === 'fr' && this.$store.getters.getLang === 'en-CA') {
      // Set the lang forced
      this.$store.dispatch('setLang', { lang: 'fr-CA' })
      this.$i18n.locale = 'fr-CA'
    }

    // watcher when the user region change
    this.$store.watch(
      (state, getters) => getters.getMyRegion,
      (newValue, oldValue) => {
        // if is the first visite and the user is in the Quebec
        if (this.$store.getters.myLangIsDefined === false && this.$store.getters.getMyRegion === 'QC') {
          // force lang to french and set is defined
          this.$store.dispatch('setLang', { lang: 'fr-CA' })
          this.$i18n.locale = 'fr-CA'
          this.$store.dispatch('setMyLangIsDefined', { langIsDefined: true })
        } else {
          // set the lang is defined
          this.$store.dispatch('setMyLangIsDefined', { langIsDefined: true })
        }
      }
    )
  },
  beforeMount () {
    // If the user is in Canada
    if (this.$store.getters.getCountry === 'Canada') {
      // Add this CA css class for switching headers typo
      document.body.classList.add('CA')
    }
  },
  mounted () {
    // VH polyfill for mobile
    this.vhRoot()
    // add global click event
    this.globalClick()

    this.$nextTick(() => {
      setTimeout(() => {
        this.objectFitImages()
      }, 3000)
    })

    setTimeout(() => {
      // Attach cookie pro script
      this.getCookiePro()
    }, 3000)

    /**
     * @TODO: clear the selected shop, remove this function to disable the clear cache
     */
    setTimeout(() => {
      this.$store.dispatch('setSelectedShop', { shopId: null })
    }, 1000)
  }
}
</script>
