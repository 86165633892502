<template>
    <section v-if="columns.length && columns[0].is_page === 'true'" class="subnavigation-page container-fluid">
      <div class="row no-gutters">
        <div class="col-12 col-lg-10 offset-lg-1">
            <ul class="subnavigation-page__wrapper row">
              <li class="subnavigation-page__nav col-12 col-md-6" v-for='page1 in columns' :key='page1.slug' >
                <span class="subnavigation-page__link-lvl1">
                  <router-link :to="page1.full_url">
                      {{ page1.title }}  <icon-arrow />
                  </router-link>
                </span>
                <ul v-for='page2 in page1.child_pages' :key='page2.slug' >
                  <li>
                    <router-link :to="page2.full_url" class="subnavigation-page__sublink">
                      {{ page2.title }}
                    </router-link>
                  </li>
                 </ul>
              </li>
            </ul>
          </div>
      </div>
    </section>
</template>

<script>
import IconArrow from '../icons/IconArrow.vue'

export default {
  name: 'SubNavigationPage',
  components: {
    IconArrow
  },
  data () {
    return {
      columns: [],
      title: ''
    }
  },
  created () {
    const tree = this.$store.getters.getSiteMap
    const path = document.location.pathname.split('/')
    const currentLevel = path.length - 1
    if (currentLevel > 2) {
      this.columns = this.getPageChildren(tree, path[currentLevel])
    }
  },
  methods: {
    getPageChildren (tree, slug) {
      const result = []
      if (tree.slug === slug) {
        return tree.child_pages
      }
      tree.child_pages.forEach((element) => {
        const childResult = this.getPageChildren(element, slug)
        if (childResult.length > 0) {
          childResult.forEach((child) => {
            result.push(child)
          })
          return result
        }
        return result
      })
      return result
    }
  }
}
</script>
<style lang="scss" scoped>
.subnavigation-page {
  padding-top: 4rem;
  padding-bottom: 4rem;
  ul,
  li {
    list-style: none;
    margin: 0;
  }
  &__wrapper {

  }
  &__nav {
    margin-bottom: 3rem !important;
    @include breakpoint("lg") {
      padding-right: 4rem;
    }
  }
  &__link-lvl1 {
      a {
        font-size: 1.8rem;
        padding-bottom: 2rem;
        margin-bottom: 2rem;
        border-bottom: 1px solid $grey-light;
        font-weight: 600;

        display: flex;
        align-items: center;
        justify-content: space-between;

        svg {
          width: 1.4rem;
        }
      }
  }
  &__sublink {
    color: $dark-grey-b2b;
    margin-bottom: 0.7rem;
    display: inline-block;
  }
}
</style>