<template>
  <section class="breadcrumb-header container-fluid" :style="{ backgroundImage: `url(${leading_image})` }">
    <div class="row no-gutters">
      <div class="col-12 col-lg-10 offset-lg-1">
        <div class="row no-gutters">
          <div class="breadcrumb-header__content col">
            <nav class="breadcrumb-header__nav">
              <ul>
                <li v-for="(page, index) in leaf" :key="page.slug">
                  <b-link v-if="index < leaf.length - 1" :href="page.url"
                    >{{ page.title }} <icon-chevron
                  /></b-link>
                  <span v-if="index == leaf.length - 1">{{ page.title }}</span>
                </li>
              </ul>
            </nav>

            <h1 class="breadcrumb-header__title">{{ title }}</h1>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import IconChevron from '../icons/IconChevron.vue'

export default {
  name: 'BreadcrumbsPage',
  components: {
    IconChevron
  },
  data () {
    return {
      leaf: [],
      title: '',
      leading_image: ''
    }
  },
  created () {
    const tree = this.$store.getters.getSiteMap
    const path = document.location.pathname.split('/')
    const result = []
    path.forEach((element) => {
      const record = this.getPathPageChild(tree, element)
      if (record.length > 0) {
        result.push(record[0])
      }
    })
    this.leaf = result
    this.title = this.$store.getters.getPageTitle
    this.leading_image = this.$store.getters.getPageLeadingImage
  },
  methods: {
    getPathPageChild (tree, slug) {
      const result = []
      if (tree.slug === slug) {
        result.push({ title: tree.title, url: tree.full_url })
      } else {
        tree.child_pages.forEach((element) => {
          const childResult = this.getPathPageChild(element, slug)
          if (childResult.length > 0) {
            result.push(childResult[0])
          }
        })
      }
      return result
    }
  }
}
</script>
<style lang="scss" scoped>
.breadcrumb-header {
  background-color: $grey-b2b;
  background-repeat: no-repeat;
  background-position:  right bottom;
  background-size: 50%;

  @include breakpoint("lg") {
    background-size: contain;
    background-position:  83.3333333333% bottom;
  }

  &__nav {
    margin-bottom: 3rem;
    ul {
      list-style: none;
      margin: 0;
      @include breakpoint("md") {
        display: flex;
      }
    }
    li {
      margin-right: 2.5rem;
      font-weight: 600;
      &:first-child {
        a {
          color: $menu-active;
        }
        &::first-letter {
          text-transform: uppercase;
        }
      }
      .icon {
        width: 0.8rem;
        display: inline-block;
        margin-left: 2rem;
        svg {
          width: 0.8rem;
        }
      }
    }
  }
  &__content {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  &__title {
    font-size: 4rem;
  }
}
</style>