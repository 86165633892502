module.exports = {
  VUE_APP_ENC: 'U2FsdGVkX1+iz0YXX9VICeCSUgqy/owBmVYmWvFeoLs=',
  VUE_APP_API_HOST:
    'U2FsdGVkX183VHZuqnGMXSwaQ4nESzNFqsctsXBDAnkyXxFzRz8IpYl0yOtY8v2c',
  VUE_APP_GOOGLE_MAP_API_KEY:
    'U2FsdGVkX1+/WzM8vu2X1L5HL4HqDpMDjEmIrP+92poQjs18ioPlLEDQAkV9CIWPVrCUZlPWBq5POryZ6FtyDA==',
  VUE_APP_GEOIP_API_KEY: 'U2FsdGVkX19FagPxOJUwFkzJJd5IF6lyREjKiyGtvQk=',
  VUE_APP_S3D_CLOUDFRONT_ID: 'U2FsdGVkX19oyVE5lEJNycI3csQT3XZRRdLlIgPUDcA=',
  VUE_APP_S3D_BUCKET: 'U2FsdGVkX1+n6PCAxmUPjQK5X1sc5AD6BX8WXW+g+eI=',
  VUE_APP_SENDGRID_API_KEY:
    'U2FsdGVkX18sNoCgNzprQ6yns7cl9mdUx3Wl+4AIZr+WYoPXYGXaEgb6aMN1edDSwck9eYLnqTp35IhDTzvEuCs25D/rhAHKxREvWHHGVPRG3t5mJ2dZXZ9yXepSK8lY',
  VUE_APP_MAILCHIMP_API_KEY:
    'U2FsdGVkX1/QaMOe0AAN36N8va7Jx1tKY2rmffnNKuqPidyvnW9Rdiw9CNrYTpeZr4B+wWuREfbm0x67/Cw4gA==',
  VUE_APP_GRAPHQL_AUTH:
    'U2FsdGVkX1/TqGQXsLVLT1op+P+73FaUe0nwzHEvXcvPg59QeRPYceKrCOWgc/r/',
  BASE_PDF_DL_URL:
    'U2FsdGVkX19tILcNq7Ps1jLQJ9UkHX+fM5EW3vqfQ+oAolMKA2C/vKCs4dNx4u5tVuM/ckxzzWyi7/1aPTjYrX43lkcZdesTCrj+IXB7rCWYi5ZMBGb1RdBIxpxDX58F'
}
