<template>
  <div class="app-container">
    <Header v-if="windowWidth >= '1025'"/>
    <HeaderMobile v-else/>
    <Alert :text="alertMessage" :show="alertDisplay" :theme="alertTheme"/>
    <BreadcrumbsPage v-if="!isHome" />
    <SubNavigationPage />
    <section class="app-body">
      <slot/>
    </section>
    <Footer/>
  </div>
</template>

<script>
import Header from '../components/layouts/infosax/Header.vue'
import HeaderMobile from '../components/layouts/infosax/HeaderMobile.vue'
import BreadcrumbsPage from '../components/common/BreadcrumbsPage.vue'
import SubNavigationPage from '../components/common/SubNavigationPage.vue'
import Footer from '../components/layouts/infosax/Footer.vue'
import Alert from '../components/common/Alert.vue'
import NewsletterModal from '../components/common/NewsletterModal.vue'
import SelectRegion from '../components/common/SelectRegion.vue'
import { SITE_INFO_SAX } from '../router/configSites'

export default {
  name: 'MainInfoSax',
  components: {
    Header,
    HeaderMobile,
    Footer,
    Alert,
    NewsletterModal,
    SelectRegion,
    BreadcrumbsPage,
    SubNavigationPage
  },
  data () {
    return {
      alertDisplay: this.$store.getters.getAlertDisplay(this.$i18n.locale),
      alertMessage: this.$store.getters.getAlertMessage(this.$i18n.locale),
      alertTheme: this.$store.getters.getAlertTheme
    }
  },
  methods: {
    /**
     * Check the modal state for display them
     * @returns {Void}
     */
    checkModalState () {
      // If the modal haven't display
      if (!localStorage.modalNewsletterFirstLoad) {
        // display the modal after 5 seconds
        setTimeout(() => {
          this.$bvModal.show('b2b-modal-newsletter')
        }, 5000)
        // change the state of the modal
        localStorage.modalNewsletterFirstLoad = true
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.checkModalState()
    })
  },
  computed: {
    isHome () {
      const regex = new RegExp(`^\/(fr\/${SITE_INFO_SAX.slugFr}|en\/${SITE_INFO_SAX.slugEn})(\\/|)$`, 'g')
      return window.location.pathname.match(regex)
    }
  }
}
</script>

<style lang="scss">
.app-container {
  transition: padding 0.2s ease;
  padding-top: 45px;
  @include breakpoint('lg') {
    padding-top: 100px;
  }
}
</style>
