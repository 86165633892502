<template>
    <section class="breadcrumb-nav row no-gutters">
        <div class="sub-nav-toggler" @click="toggleSubNav()">
          {{menuTitle}}
          <div class="sort-cta">
            <svg class="rotated" xmlns="http://www.w3.org/2000/svg" width="9.193" height="9.191">
              <path d="M.354 4.2418l4.2426 4.2427 4.2426-4.2427" data-name="Tracé 835" fill="none" stroke="red"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="9.193" height="9.191">
              <path d="M.354 4.2418l4.2426 4.2427 4.2426-4.2427" data-name="Tracé 835" fill="none" stroke="red"/>
            </svg>
          </div>
        </div>

        <nav class="breadcrumb-nav__wrapper col-lg-9 offset-lg-2"
             :class="subNavActive ? 'nav-opened' : ''"
        >
          <div class="row no-gutters">
            <b-link v-if="displayBack" @click="back()" class="breadcrumb-nav-item __all">
                <div>
                    <svg class="icon icon__sm" id="arrow-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.49 31.49"><path data-v-146e2983="" d="M21.205 5.007a1.112 1.112 0 00-1.587 0 1.12 1.12 0 000 1.571l8.047 8.047H1.111A1.106 1.106 0 000 15.737c0 .619.492 1.127 1.111 1.127h26.554l-8.047 8.032c-.429.444-.429 1.159 0 1.587a1.112 1.112 0 001.587 0l9.952-9.952a1.093 1.093 0 000-1.571l-9.952-9.953z" fill="#9b9b9b"></path></svg>
                </div>
                <label v-if="islabelButtonBack">{{ $t('generalUi.back') }}</label>
                <label v-else>{{ $t('generalUi.all') }}</label>
            </b-link>
            <b-link
              v-for="element in parents"
              :key="element.key"
              :to="prepareParentSlug(element)"
              class="breadcrumb-nav-item __all"
              :class="element.class"
              >
                <div v-if="element.icon" >
                  <div class="icon icon__lg" v-html="element.icon" ></div>
                </div>
                <label >{{ element.name }}</label>
            </b-link>
            <b-link
              v-for="element in elements"
              :key="element.key"
              :to="prepareSlug(element)"
              class="col-lg-1 breadcrumb-nav-item ">
                <div v-if="element.icon" >
                  <div class="icon icon__lg" v-html="element.icon" ></div>
                </div>
                <label>{{ element.name }}</label>
            </b-link>
          </div>
        </nav>
    </section>
</template>

<script>
import gql from 'graphql-tag'
import { GET_FR_PARENT, GET_EN_PARENT } from '../../graphql/queries/products'
import { GET_FR_PAGE_PARENT, GET_EN_PAGE_PARENT } from '../../graphql/queries/pages'
import hackQL from '../../utils/hackQL'
import EventBus from '../../utils/events'

export default {
  name: 'Breadcrumbs',
  data () {
    return {
      parents: [],
      displayBack: false,
      productName: 'Product',
      subNavActive: false
    }
  },
  props: {
    elements: {
      type: Array,
      default: () => []
    },
    menuTitle: {
      type: String,
      default: ''
    },
    menuType: {
      type: String,
      default: 'product'
    }
  },
  methods: {
    /**
     * Hide/show sub navigation
     * @returns {Void}
     */
    toggleSubNav () {
      this.subNavActive = !this.subNavActive
    },
    islabelButtonBack () {
      return true
    },
    /**
     * Prepare the parent slug url for the product page
     * @param {Object} element
     * @returns {Object}
     */
    prepareParentSlugProduct (element) {
      // create the slug by lang
      let slug = (this.$i18n.locale === 'fr-CA') ? '/fr' : '/en'
      // Add the good path by lang for product
      slug += `/${hackQL.getPath('products')[(this.$i18n.locale === 'fr-CA') ? 1 : 0]}`
      // default params
      let query = {}

      // if we use the element slug
      if (!element.buildSlug) {
        // eslint-disable-next-line prefer-destructuring
        slug = element.slug
      } else {
        let i = 0
        let url = ''

        // loop on parents
        this.parents.forEach((item, key) => {
          // if the current element is not the item
          if (key <= element.key) {
            // Add item slug to the url
            slug += `/${item.slug}`
          }
          // Split the params entity id
          query = this.$route.query.p.split('.')

          // loop on all params
          query.forEach((queryItem) => {
            // if the current element is not the item, add the entity id to the params
            if (i <= element.key) {
              url += `${(i > 0) ? '.' : ''}${queryItem}`
            }
            i += 1
          })
        })

        query = {
          p: url
        }
      }

      return {
        path: slug,
        query
      }
    },
    /**
     * Prepare the parent slug url
     * @param {Object} element
     * @returns {Object}
     */
    prepareParentSlug (element) {
      // if we are on the product page
      if (this.$route.query && this.$route.query.h && this.$route.query.h === 'products') {
        return this.prepareParentSlugProduct(element)
      }

      let slug = ''
      let query = {}

      // create the base slug url
      const backUrl = this.$route.path.split('/')
      slug = backUrl.slice(0, (element.key + 4))
      slug = slug.join('/')

      // if we have params
      if (this.$route.query && this.$route.query.p) {
        query = this.$route.query.p.split('.')
        let i = 0
        let url = ''
        // Format the params
        query.forEach((item) => {
          if (i <= (element.key)) {
            url += `${(i > 0) ? '.' : ''}${item}`
          }
          i += 1
        })
        query = {
          p: url
        }
      }

      return {
        path: slug,
        query
      }
    },
    /**
     * Prepare the slug for add params
     * @param {Object} element
     * @returns {Object}
     */
    prepareSlug (element) {
      // by default no params
      let parent = ''

      // if we don't have params
      if (this.$route.query.p === undefined) {
        // add the element entity id as params
        parent = element.key
      } else {
        // add the current element entity id at the end of params
        parent = `${this.$route.query.p}.${element.key}`
      }

      return {
        path: element.slug,
        query: {
          p: `${parent}`
        }
      }
    },
    /**
     * Go back when we are in product detail page
     * @returns {Void}
     */
    backToProduct () {
      this.$router.replace(this.prepareParentSlugProduct(this.parents[(this.parents.length - 2)]))
    },
    /**
     * When we click on back button
     * @returns {Void}
     */
    back () {
      // if we have params history
      if (this.$route.query && this.$route.query.b && this.$route.query.b === 'history') {
        // use router history to go back
        this.$router.back()
        return
      }

      // if we are on preview mode
      if (this.$route.query && this.$route.query.content_type) {
        // go to home
        this.$router.replace({
          path: '/',
          query: {}
        })
        return
      }

      // if we are in the product detail page
      if (this.$route.query && this.$route.query.h && this.$route.query.h === 'products') {
        // use the product back function
        this.backToProduct()
        return
      }

      // return to the "home" catalogue (/en/our-service or /en/our-product)
      const backUrl = this.$route.path.split('/')

      setTimeout(() => {
        this.$router.replace({
          path: backUrl.slice(0, 3).join('/'),
          query: {}
        })
      })
    }
  },
  created () {
    // Event when we get the product name to add on the breadcrumbs
    EventBus.$on('set-name-breadcrumbs', (params) => {
      this.productName = params.value
    })
  },
  async mounted () {
    // Send event for get the product name
    EventBus.$emit('get-name-breadcrumbs')

    // If is not the root
    if (this.$route.path.split('/').length > 3) {
      // display the back button
      this.displayBack = true
    }
    // if we are on preview mode and the preview is products or services categories
    const forceBackParent = ((this.$route.query.content_type === 'product_category') || (this.$route.query.content_type === 'services_category'))

    // if we have params or is service / product categories on mode preview
    if ((this.$route.query && this.$route.query.p) || forceBackParent) {
      let backParent = []

      // if is the page
      if (this.$route.query && this.$route.query.p) {
        backParent = this.$route.query.p.split('.')
      }

      // if is preview mode
      if (forceBackParent) {
        backParent.push(this.$route.params.entity)
      }

      const allPromise = []

      // Get all parents data (icon and name)
      await backParent.forEach(async (item, key) => {
        if (this.menuType === 'pages') {
          allPromise.push(this.$apollo.query({
            query: gql`query {
                ${(this.$i18n.locale === 'fr-CA')
    ? GET_FR_PAGE_PARENT.replace(/\$entity/g, item).replace(/\$region/g, this.$store.getters.getMyRegion).replace(/\$site_id/g, this.$store.SITE_ID)
    : GET_EN_PAGE_PARENT.replace(/\$entity/g, item).replace(/\$region/g, this.$store.getters.getMyRegion).replace(/\$site_id/g, this.$store.SITE_ID)}
            }`
          }))
        } else {
          allPromise.push(this.$apollo.query({
            query: gql`query {
                ${(this.$i18n.locale === 'fr-CA')
    ? GET_FR_PARENT.replace(/\$entity/g, item)
    : GET_EN_PARENT.replace(/\$entity/g, item)}
            }`
          }))
        }
      })
      // when all request is finish
      Promise.all(allPromise).then((item) => {
        // loop on the data
        item.forEach((response, key) => {
          // if we have data, format the parent item for the breadcrumbs
          if (response.data && response.data.product_category) {
            let icon = null
            if (response.data.product_category.icon) {
              const iconElement = this.$JSON5.parse(response.data.product_category.icon)
              if (iconElement && iconElement.file && iconElement.file.html) {
                icon = iconElement.file.html
              }
            }

            this.parents.push({
              key,
              name: response.data.product_category.name,
              slug: response.data.product_category.slug,
              icon,
              class: '',
              buildSlug: true
            })
          }
          // case for pages
          if (response.data && this.menuType === 'pages') {
            this.parents.push({
              key,
              name: response.data.page.page_title,
              slug: response.data.page.slug,
              icon: null,
              class: '',
              buildSlug: true
            })
          }
        })
        let activeClass = 'my_child_active'

        // if we are on the product detail page
        if (this.$route.query && this.$route.query.h) {
          // add empty element with the product name
          this.parents.push({
            key: (this.parents.length),
            name: `${this.productName}`,
            icon: null,
            slug: `${window.location.pathname}${window.location.search}`,
            class: '',
            buildSlug: false
          })

          activeClass = 'my_child_active'
          // add active class on the product link on the navigation
          const selectedMain = `/${(this.$i18n.locale === 'fr-CA') ? 'fr' : 'en'}/${hackQL.getPath('products')[(this.$i18n.locale === 'fr-CA') ? 1 : 0]}`
          document.querySelectorAll(`.primary-nav__bottom a[href='${selectedMain}']`)[0].className = 'router-link-exact-active router-link-active'
        }

        // order the element parents
        this.parents.sort((a, b) => ((a.key > b.key) ? 1 : -1))
        // remove the class active
        this.parents.forEach((elmt, k) => {
          this.parents[k].class = ''
        })
        // add class active on the last element
        this.parents[(this.parents.length - 1)].class = activeClass
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.breadcrumb-nav {
  border-bottom: 1px solid $grey-light;
  background-color: white;

  position: fixed;
  top: 45px;
  left: 0;
  z-index: 8;
  width: 100vw;

  @include breakpoint('lg'){
    top: 100px;
    left: 0;
  }

  &__wrapper{
    height: 0;
    overflow: auto;
    transition: all 0.3s ease;

    @include breakpoint('lg') {
      height: auto;
      overflow: visible;
    }
  }
  .sub-nav-toggler{
    height: 7rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 1.6rem;
    text-transform: uppercase;
    position: relative;
    padding: 0 5rem 0 3.5rem;

    @include breakpoint('lg') {
      display: none;
    }

    .sort-cta{
      position: absolute;
      right: 2.5rem;
      top: 50%;
      transform: translateY(-50%);

      svg{
        display: block;
        &.rotated{
          transform: rotate(180deg)
        }
      }
    }
  }
  .nav-opened{
    height: calc(100vh - 116px);
    overflow: auto;

    @include breakpoint('lg') {
      height: initial;
    }
  }
  .breadcrumb-nav-item{
      width: 100vw;
      flex: 0 1 100%;
      height: 7rem;

      border: 1px solid $grey-light;
      border-width: 1px 1px 0px 0px;
      position: relative;
      &:first-child{
        border-width: 1px 1px 0px 1px;
      }

      font-size: 1.3rem;
      display: inline-flex;
      justify-content: center;
      text-decoration: none;
      cursor: pointer!important;

      @include breakpoint('lg') {
        text-align: center;
        width: 8rem;
        flex: 0 1 8rem;
        height: 8rem;
        flex-direction: column;
        font-size: 1rem;
      }

      div,label{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        cursor: pointer!important;
      }

      > div{
        position: absolute;
        right: 2rem;
        top: 50%;
        transform: translateY(-50%);
        opacity:0.5;

        @include breakpoint('lg') {
          position: static;
          transform: none;
          right: initial;
          margin-right: 0;
          justify-content: center;
          flex: 0 1 50%;
          padding-top:0.5rem;
        }
      }

      label{
        align-items: center;
        line-height: 1.1;
        font-weight: 600;
        flex: 0 1 70%;
        justify-content:center;
        text-align: center;

        @include breakpoint(lg){
          align-items: flex-start;
          font-weight: 400;
          flex: 0 1 50%;
          padding: 0.5rem 3px 3px;
        }
      }

      &.__all{
        background-color: $dark-grey-b2b;
        border-color: $dark-grey-b2b;
        color: $white;
      }

      &.__active,
      &:hover{
        background-color: $menu-active;
        color: $white;
      }
  }
}
</style>
