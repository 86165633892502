<template>
  <div>
    <b-modal
      id="b2b-modal-newsletter"
      size="lg"
      centered
      scrollable
      :title="$t('generalUi.modalNewsletterTitle')"
      :hide-footer="true"
    >
      <form 
          action="" 
          @submit="checkForm()" 
          @submit.prevent
          v-if="!success"
      >
        <div class="wrapper">

            <div
            class="input-wrapper __double text-left"
            :class="{ __error: $v.email.$error }"
            >
            <div>
                <input
                type="text"
                v-model.trim="$v.fname.$model"
                :placeholder="$t('formValidation.fname')"
                />
                <p class="error" v-if="!$v.fname.required">
                    {{ $t("formValidation.required") }}
                </p>
            </div>

            <div
                class="text-left"
                :class="{ __error: $v.lname.$error }"
            >
                <input
                type="text"
                v-model.trim="$v.lname.$model"
                :placeholder="$t('formValidation.lname')"
                />
                <p class="error" v-if="!$v.lname.required">
                {{ $t("formValidation.required") }}
                </p>
            </div>

            </div>

            <div
            class="input-wrapper text-left"
            :class="{ __error: $v.email.$error }"
            >
            <input
                type="text"
                v-model.trim="$v.email.$model"
                :placeholder="$t('generalUi.email')"
            />
            <p class="error" v-if="!$v.email.required">
                {{ $t("formValidation.required") }}
            </p>
            </div>

            <div class="input-wrapper"
                 :class="{ __error: $v.leadtype.$error }">
                <select v-model.trim="$v.leadtype.$model" @change="$v.leadtype.$touch()">
                    <option value="">{{ $t("formValidation.whoru") }}</option>
                    <option value="magasin">{{ $t("formValidation.magasin") }}</option>
                    <option value="atelier">{{ $t("formValidation.atelier") }}</option>
                    <option value="consommateur">{{ $t("formValidation.consommateur") }}</option>
                </select>
                <p class="error" v-if="!$v.leadtype.required">
                    {{ $t("formValidation.required") }}
                </p>
            </div>

            <div
              class="input-wrapper"
              :class="{ __error: $v.newsletterConsent.$error }"
            >
              <label class="container-checkbox">
                    <small  v-html="$t('appointementConsentBtn')"></small>
                    <input
                      type="checkbox"
                      id="newsletterConsent"
                      name="newsletterConsent"
                      value="no"
                      v-model.trim="$v.newsletterConsent.$model"
                    >
                    <span class="checkmark"></span>
              </label>
              <p class="error" v-if="!$v.newsletterConsent.required">
                  {{ $t("formValidation.required") }}
              </p>
            </div>

            <div class="text-right">
              <button :disabled="!newsletterConsent" type="submit" value="" class="btn">
                <span v-if="submitStatus === 'PENDING'">{{
                    $t("generalUi.submiting")
                }}</span>
                <span v-if="submitStatus === 'OK'">{{
                    $t("generalUi.submitedSub")
                }}</span>
                <span v-if="submitStatus === 'NOTOK'">{{
                    $t("generalUi.submitedError")
                }}</span>
                <span v-if="submitStatus === 'READY'">{{
                    $t("footer.newsletterBtn")
                }}</span>
              </button>
            </div>


        </div>
      </form>

      <div class="thank-you" v-else>
        <h2 v-html="$t('generalUi.submitedSub')"></h2>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import { SEND_MAILCHIMP } from '../../graphql/mutations/mailChimp'

export default {
  name: 'NewsletterModal',
  data () {
    return {
      userLang: this.$store.state.user.lang,
      userProvince: this.$store.state.user.region,
      currentPage: window.location.href,
      userShop: '',
      userStore: this.$store.state.store.selectedShop
        ? this.$store.state.store.selectedShop.name
        : '',
      leadtype: '',
      fname: '',
      lname: '',
      email: '',
      newsletterConsent: false,
      listId: this.$store.getters.getMailChimpId(this.$i18n.locale) ? this.$store.getters.getMailChimpId(this.$i18n.locale) : 'f9fd87f937',

      submitStatus: 'READY',
      success: false,
      error: null,
      loading: false
    }
  },
  validations: {
    leadtype: {
      required
    },
    fname: {
      required
    },
    lname: {
      required
    },
    email: {
      required,
      email
    },
    newsletterConsent: {
      checked: value => value === true
    }
  },
  mounted () {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.success = false
    })
  },
  methods: {
    /**
     * Validate the form and submit
     * @returns {Void}
     */
    checkForm () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        // this.submitStatus = 'ERROR'
      } else {
        // set the status
        this.submitStatus = 'PENDING'

        // prepare data
        const msg = {
          id: this.listId,
          FNAME: this.fname,
          LNAME: this.lname,
          LEADTYPE: this.leadtype,
          EMAIL: this.email.trim(),
          ULANG: this.userLang,
          USHOP: this.userShop,
          USTORE: this.userStore,
          UPROVINCE: this.userProvince,
          USPAGE: this.currentPage
        }

        // submit
        this.subscribe(msg)
      }
    },
    /**
     * Send request for the submission
     * @param {Object} data
     * @returns {Void}
     */
    subscribe (data) {
      // send the query
      this.$apollo
        .query({
          query: SEND_MAILCHIMP,
          variables: data
        })
        .then((response) => {
          this.submitStatus = 'OK'

          this.success = true
          this.submitStatus = 'READY'

          this.fname = ''
          this.lname = ''
          this.leadtype = ''
          this.email = ''
          this.newsletterConsent = false

          this.$v.$reset()
        })
        .catch((error) => {
          this.submitStatus = 'NOTOK'
          setTimeout(() => {
            this.submitStatus = 'READY'
          }, 2500)
          // eslint-disable-next-line
          console.log(error);
        })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .modal-content{

  padding: 3.5rem;

  @include breakpoint("mdpi") {
    padding: 8rem 8rem 6rem;
  }
  .modal-header {
    border: 0;
    padding: 0;
    margin-bottom: 1.5rem;

      @include breakpoint("mdpi") {
        margin-bottom: 3rem;
      }
  }

  .modal-body{
    padding: 0;
  }

  .close{
    text-indent: -5000px;
    background-image: url('~@/assets/icons/cross.svg');
    background-repeat: no-repeat;
    background-position: center;

    position: absolute;
    top: 15px;
    right: 25px;

    @include breakpoint("mdpi") {
      top: 30px;
      right: 40px;
    }
  }

  .btn{
    margin-top: 2rem;
  }
}
.thank-you{
  padding: 4rem 0;
}
</style>
