export default {
  metaInfo: {
    title: 'Unipro : If it\'s Unipro, it\'s a pro!',
    titleTemplate: '| Unipro',
    htmlAttrs: {
      lang: 'en'
    },
    meta: {
      description: 'We understand that when you need a part, you need it now. With the widest distribution network in Canada, whether you\'re a consumer, automotive professional, or wholesaler, we\'ll get you the products you need.'
    }
  },
  header: {
    lang: 'FR'
  },
  footer: {
    newsletterTitle: 'Subscribe to our Newsletter',
    newsletterPlaceholder: 'Email',
    newsletterBtn: 'Subscribe'
  },
  pages: {
    siteRoot: {
      infobumpertobumper: 'bannerprogram'
    },
    singleShop: {
      title: 'Our services',
      contact: 'Contact us',
      directions: 'Get Directions',
      close: 'Closed'
    },
    findShop: {
      title: 'Find a store',
      findShopLabel: 'Find a shop',
      intro: 'Our network encompasses more than 4000 repair shops in Canada, including the Uni-Pro and Auto-Select brands, where you can always count on highly skilled and professional technicians who’s priority is always customer satisfaction.',
      placeHolderLocation: 'Address / Postal code',
      placeHolderName: 'By name',
      or: 'or',
      btnFindShop: 'Find a store',
      searchLabel: 'Search',
      btnAdvancedSearch: 'Advanced search',
      btnSelectShop: 'Select this store',
      btnDetail: 'See details',
      resetFilter: 'Reset filter',
      website: 'Website'
    },
    findShopDetail: {
      select: 'Select this shop',
      change: 'Back to results'
    }
  },
  errors: {
    shopNotFound: 'Sorry, this shop was not found!',
    pageError: 'Sorry, we got an error on this page!',
    pageNotFound: {
      text: 'The page you are looking for was not found.',
      button: 'Back to Home'
    },
    internal: {
      title: 'Unexpected Error',
      text: 'You can go back to our',
      link: 'Homepage'
    },
    previewNotFound: 'Preview is not activated for this content type'
  },
  generalUi: {
    all: 'All',
    back: 'Back',
    myShop: 'My shop',
    findStore: 'Find a store',
    findMyShop: 'Find my shop',
    login: 'Login',
    copyright: 'All Contents ©__year__ Uni-Select Inc. All rights reserved.<br>Uni-Pro is a registered trademark of Uni-Select Inc.',
    download: 'Download',
    learnMore: 'Learn more',
    exclusiveBrands: 'Our Exclusive Brands',
    notExclusiveBrands: 'Our Brands',
    writeToUs: 'Write to us',
    send: 'Send',
    submiting: 'Sending...',
    submited: 'Message sent ! Thank you!',
    submitedSub: 'Subscription sent !<br>Thank you!',
    submitedError: 'An error append',
    name: 'Name',
    email: 'Email',
    phone: 'Phone',
    search: 'Search',
    searchCount: 'Search Results - {count} result(s) for {query}',
    product: 'Product',
    brand: 'Brand',
    categories: 'Categories',
    hideCategories: 'Hide Categories',
    openCategories: 'Open Categories',
    modalNewsletterTitle: 'Enroll to our newsletter !',
    searchArticle: 'Search article',
    selectRegion: 'Choose a region :',
    noResult: 'Sorry we have no result for this search',
    noResultInRadius: 'We did not find any results within 50 km from the specified location.',
    goBack: 'Back to menu'
  },
  linksLablel: {
    allproducts: 'All products'
  },
  formValidation: {
    required: 'Please fill this field above',
    minLength: 'Please this field must have at least {number} letters.',
    email: 'Please enter a corrected formated email like yourmail@gmail.com',
    numeric: 'Please, only numeric value for this field',
    phone: 'Please use a valid phone number',
    fname: 'Your first name',
    lname: 'Your last name',
    whoru: 'Who are you?',
    magasin: 'Parts store',
    atelier: 'Repair',
    consommateur: 'Consumer'
  },
  backToTop: 'Go to top',
  readMore: 'Read More',
  readLess: 'Read Less',
  newsletterConsent: 'By clicking on subscribe, I agree to receive communications that may contain promotions, offers or other relevant information. It will be possible to withdraw consent at any time. For more information, please consult our <a class=\'underline\'  href=\'https://www.lkqcorp.com/privacy/\' target=\'blank\' ><u>privacy policy</u></a>.',
  appointementConsentBtn: 'I agree to receive communications from Uni-Pro that may contain promotions, offers or other relevant information. It will be possible to withdraw consent at any time. For more information, please consult our <a class=\'underline\'  href=\'https://www.lkqcorp.com/privacy/\' target=\'blank\' ><u>privacy policy</u></a>.',
  appointementConsentBis: 'I agree that Uni-Pro may use my data as described in their <a class=\'underline\'  href=\'https://www.lkqcorp.com/privacy/\' target=\'blank\' ><u>privacy policy</u></a>.',
  privacy_text: 'Privacy policy',
  privacy_link: 'https://www.lkqcorp.com/privacy/'
}
