export default [
  {
    from: '/en/privacy',
    to: 'https://www.lkqcorp.com/privacy/'
  },
  {
    from: '/fr/confidentialite',
    to: 'https://www.lkqcorp.com/privacy-fr/'
  },
  {
    from: '/en/privacy/',
    to: 'https://www.lkqcorp.com/privacy/'
  },
  {
    from: '/fr/confidentialite/',
    to: 'https://www.lkqcorp.com/privacy-fr/'
  }
]
