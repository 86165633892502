module.exports = {
  VUE_APP_ENC: 'U2FsdGVkX19TB/no01isqyqfAPHD3l9MtJiqE1Z0m8U=',
  VUE_APP_API_HOST:
    'U2FsdGVkX186EHddTOugO3EGbd1X1V3KJf45JVHBtU/FHHvUWRYyBpjBa63T7l1OqwrtGW4XW0tiGbSCMBZ32w==',
  VUE_APP_GOOGLE_MAP_API_KEY:
    'U2FsdGVkX19T3/PWV04kU08a8iFf4DrLyoClT/+V6ob0xtSAWEZQ3nybgWLlpsqE38Q13ggi0bLGB1aYXgi8Zw==',
  VUE_APP_GEOIP_API_KEY: 'U2FsdGVkX1+ezExHpaXky8LzbPkzBaQGUd1lZSxdgqY=',
  VUE_APP_S3D_CLOUDFRONT_ID:
    'U2FsdGVkX19wWYS4VAx5+XO7545f813RyYJ5j2aPm8+EYGe5mWlI9W444DTpYKIzWL0qa70xwu2cDyFVEX2K6A==',
  VUE_APP_S3D_BUCKET:
    'U2FsdGVkX19bVU6cw6+R+5ExvwneLvuv0lhpuR6HtoYFLcvQD+2mLzunxES9/nnU',
  VUE_APP_SENDGRID_API_KEY:
    'U2FsdGVkX1+7ROXXx8Kf3QEd6Sb31AX+GcfvThLkx6qRl7VK/+hg7RrSYNTq7MmCV4gg31qN9/fCin+YO2mpZL9kkSBetHYPhm5dpSgq6pyITh1hBxk/CJweHUJDyCRc',
  VUE_APP_MAILCHIMP_API_KEY:
    'U2FsdGVkX1++8lXBXJV9GjmElJ8ew8oVxd5I0u1yEAxmocpLnHZMVI2KrGjh4OIR8zco797gwRGhI3xZKMdKgg==',
  VUE_APP_GRAPHQL_AUTH:
    'U2FsdGVkX1+kMKQAbweZ8ekakHnpgLjRYEDGP2HJxJEv8gksP1U3bB5jYHzNRpAF',
  BASE_PDF_DL_URL:
    'U2FsdGVkX1+V9/b9k7oTv2RmUEMX8OjozexlzsIFmxwULU7ouJNtFzqjjj3ZUZIj4PCxcPWS4QdIjQEehO7Flc2jyh3bscoa2ipmGArqfDg6LVpyYsILs+94giDzA4c3'
}
