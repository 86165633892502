module.exports = {
  VUE_APP_ENC: 'U2FsdGVkX184YenVsvIPYE5ZKbniN2+zz+FaFSksusk=',
  VUE_APP_API_HOST:
    'U2FsdGVkX19NrgSuREk0phf5eMtC1tvhD2jz+syWt67cjwRVFmTi2KLtpXEUkx9hEnH1mxYNzuUFBymzvC/FIA==',
  VUE_APP_GOOGLE_MAP_API_KEY:
    'U2FsdGVkX18/mH8VRHMqzmBkrR4JjV7UGTdRwFrHLE2uCrug8PLcqlP1X+mY75M/GwbDV83zHyICkoDYBeHwmQ==',
  VUE_APP_GEOIP_API_KEY: 'U2FsdGVkX19m2OWWTGSTWw0SZLAxMwptP8lfpQ7MOY8=',
  VUE_APP_S3D_CLOUDFRONT_ID: 'U2FsdGVkX1+mpx6Vx9JaFKfrhQrqVNxMqPFlDme2crk=',
  VUE_APP_S3D_BUCKET:
    'U2FsdGVkX19EjkDxki4vJTL79apApxSIgjgFHBpV9zDTMgeEgtXcBWdOxzs8HuPS',
  VUE_APP_SENDGRID_API_KEY:
    'U2FsdGVkX1/RhVKva20TcD4aIoFpjn2cxtk1tWEtWvIYUjtQASKJzorwalvwKt4Ap4E8FqPnnl15MP5zBtKd3ZeN+RhYxvG578HACDbhzCTaVAp/C4X82SINLfSh6xLB',
  VUE_APP_MAILCHIMP_API_KEY:
    'U2FsdGVkX19oTEOyjxpiBVJ9fBprqPJfj3P6lqvW2lMomdwbcTwynazalp7qVVAKScDZz1tCBFLyVP8zsbCfUw==',
  VUE_APP_GRAPHQL_AUTH:
    'U2FsdGVkX1+i2YmkpEloyn7U6avEBmd6kWfqUWak2haGK11E7lwaAmOIoWHzZ5QL',
  BASE_PDF_DL_URL:
    'U2FsdGVkX1+tpopIO7GfHPWEZURwkbpmKqyv6YXZ5Eom3ANreaJTn40C/TzcyD9TUCg0Z9QpZOBER+fkQMlvJFx6XKjRozg9KVjYY1IRrFPnjQzinl8+8TEhi7FJrQQ+'
}
