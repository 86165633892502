import axios from 'axios'
import hackQL from '../utils/hackQL'
import { SITE_INFO_SAX } from '../router/configSites'

export default {
  data () {
    return {
      selectedShop: this.$store.getters.getSelectedShop,
      navigations: this.$store.getters.getNavigation(),
      lang: this.$store.getters.getLang,
      mainNavigation: [],
      topNavigation: [],
      loginNavigation: null,
      findStoreNavigation: null,
      findShopNavigation: null,
      findShopDetailNavigation: null,
      fullUrl: this.$store.getters.getFullUrl,

      q: '',
      searchResult: '',
      searchOpen: false,
      currentPage: 1,
      searchDisabled: false,

      searchEnKey: 'ac3a4ae49447c0d58',
      searchFrKey: 'a622c80474a440d7c',

      isBurgerActive: false,
      isSubMenuActive: false
    }
  },
  watch: {
    /**
     * Watcher when the query change
     * @returns {Void}
     */
    q () {
      this.debouncedDoSearch()
    },
    /**
     * Watcher when the search is open or not
     * @returns {Void}
     */
    searchOpen () {
      // if we close the search
      if (!this.searchOpen) {
        // reset the search page and query
        this.currentPage = 1
        this.q = ''
      }
    }
  },
  computed: {
    /**
     * Get the page total for the pagination
     * @returns {Number}
     */
    rows () {
      return parseInt(this.searchResult.searchInformation.totalResults, 10)
    },
    /**
     * @returns URL GENERATION FOR MANUAL PDF
     */
    getPdfGenerationUrl () {
      const lang = this.$store.getters.getLang
      return `${global.env.decrypt('BASE_PDF_DL_URL')}/assets/unipro-manual-${lang}.pdf`
    }
  },
  methods: {
    /**
     * Add timer for start the search when the input search change
     * @param {Function} func
     * @param {Number} wait
     * @param {Boolean} immediate
     * @returns {Void}
     */
    debounce (func, wait, immediate) {
      let timeout
      // eslint-disable-next-line func-names
      return function () {
        // eslint-disable-next-line one-var
        const context = this,
          // eslint-disable-next-line prefer-rest-params
          args = arguments
        // eslint-disable-next-line func-names
        const later = function () {
          timeout = null
          if (!immediate) func.apply(context, args)
        }
        const callNow = immediate && !timeout
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
        if (callNow) func.apply(context, args)
      }
    },
    /**
     * Event when the pagination change
     * @param {Number} page
     * @returns {Void}
     */
    paginationChange (page) {
      // Set the new current page
      this.currentPage = page
      // Start new search for the new current page
      this.doSearch()
    },
    /**
     * Start the request for search
     * @returns {Void}
     */
    doSearch () {
      axios
        .get(`https://www.googleapis.com/customsearch/v1?key=${global.env.decrypt('VUE_APP_GOOGLE_MAP_API_KEY')}&cx=${this.$store.getters.getLang === 'en-CA' ? this.searchEnKey : this.searchFrKey}&q=${this.q}&start=${(this.currentPage)}`)
        .then((response) => {
          this.searchResult = response.data
        })
        .catch((error) => { // eslint-disable-next-line
          console.log(error)
        })
    },
    /**
     * Display the login button on navigation
     * @returns {Boolean}
     */
    displayLoginPage () {
      return false
    },
    /**
     * Event when the user change the lang
     * @returns {Void}
     */
    changeLang () {
      // Set the new lang
      const lang = this.$i18n.locale === 'fr-CA' ? 'en-CA' : 'fr-CA'
      this.$store.dispatch('setLang', { lang })
      this.$i18n.locale = lang
      setTimeout(() => {
        // Build the new url for the new lang
        this.buildPathUrl()
      }, 500)
    },
    /**
     * Format the url (rule)
     * @param {String} url
     * @returns {String}
     */
    fixReplaceUrl (url) {
      // Get last charactere on the url
      const lastChar = url[url.length - 1]

      // if the url finish by "/" and is not root (/ /en/ /fr/)
      if (lastChar === '/' && url.length > 4) {
        // remove the "/"
        return url.replace(/.$/, '')
      }

      // if is the shop / store detail page, add the shop slug on the url
      if (hackQL.is(this.$route.path, 'storeDetail', 3) || hackQL.is(this.$route.path, 'shopDetail', 3)) {
        url = `${url}/${this.$route.path.split('/')[this.$route.path.split('/').length - 1]}`
      }

      return url
    },
    /**
     * Change the url
     * @param {String} lang
     * @returns {Void}
     */
    replaceFullUrl (lang) {
      // if is the url in en
      if (this.fullUrl[0] && this.fullUrl[0][lang]) {
        // redirect to the new url
        this.$router.replace({
          path: this.fixReplaceUrl(this.fullUrl[0][lang]),
          query: this.$route.query
        })

        // eslint-disable-next-line no-restricted-globals
        location.reload()
      }

      // if the url is FR
      if (this.fullUrl[1] && this.fullUrl[1][lang]) {
        // redirect to the new url
        this.$router.replace({
          path: this.fixReplaceUrl(this.fullUrl[1][lang]),
          query: this.$route.query
        })

        // eslint-disable-next-line no-restricted-globals
        location.reload()
      }
    },
    /**
     * Format the url
     * @returns {Void}
     */
    formatUrl () {
      // if is the root
      if (this.$route.path === '/') {
        // change for the root of current lang (/en/ or /fr/)
        this.$router.replace({
          path: `${this.getPathUrl()}`,
          query: this.$route.query
        })
        this.buildPathUrl()
      }
    },
    /**
     * Get the good root path for the current lang
     * @returns {String}
     */
    getPathUrl () {
      switch (this.$store.SITE_ID) {
        case 7:
          return this.getPathUrl7()
        default:
          return this.getPathUrl6()
      }
    },
    /**
     * Get the good root path for the current lang
     * @returns {String}
     */
    getPathUrl6 () {
      switch (this.lang) {
        case 'fr-CA':
          return '/fr/'
        default:
          return '/en/'
      }
    },
    /**
     * Get the good root path for the current lang
     * @returns {String}
     */
    getPathUrl7 () {
      switch (this.lang) {
        case 'fr-CA':
          return `/fr/${SITE_INFO_SAX.slugFr}`
        default:
          return `/en/${SITE_INFO_SAX.slugEn}`
      }
    },
    /**
     /**
     * Build the url path
     * @returns {Void}
     */
    buildPathUrl () {
      // Split the url for get the lang
      const url = this.$route.path.split('/')
      // if the current lang is different
      if (`/${url[1]}/` !== this.getPathUrl()) {
        // force the lang (lang on the url get the priority)
        if (this.getPathUrl().startsWith('/en/')) {
          this.replaceFullUrl('en-CA')
        } else {
          this.replaceFullUrl('fr-CA')
        }
      }
    },
    /**
     * Load the navigation menu
     * @returns {Void}
     */
    loadNavigation () {
      // loop on navigation object
      this.navigations.forEach((element) => {
        // is main navigation
        if (element.navigation_id === 'main') {
          this.mainNavigation = this.$JSON5.parse(element.element)
        }
        // if top navigation
        if (element.navigation_id === 'top') {
          this.topNavigation = this.$JSON5.parse(element.element)
        }
        // if login navigation
        if (element.navigation_id === 'login') {
          const login = this.$JSON5.parse(element.element)
          // eslint-disable-next-line prefer-destructuring
          this.loginNavigation = (login[0]) ? login[0] : null
        }
        // is find store navigation
        if (element.navigation_id === 'find-store') {
          const findStore = this.$JSON5.parse(element.element)
          // eslint-disable-next-line prefer-destructuring
          this.findStoreNavigation = (findStore[0]) ? findStore[0] : null
        }
        // is find shop navigation
        if (element.navigation_id === 'find-shop') {
          const findShop = this.$JSON5.parse(element.element)
          // eslint-disable-next-line prefer-destructuring
          this.findShopNavigation = (findShop[0]) ? findShop[0] : null
        }
        // is shop detail navigation
        if (element.navigation_id === 'find-shop-detail') {
          const findShopDetail = this.$JSON5.parse(element.element)
          // eslint-disable-next-line prefer-destructuring
          this.findShopDetailNavigation = (findShopDetail[0]) ? findShopDetail[0] : null
        }
      })
    }
  },
  created () {
    // Prepare the timer for search when user change the input
    this.debouncedDoSearch = this.debounce(this.doSearch, 200)

    // Watch when the selected shop change
    this.$store.watch(
      (state, getters) => getters.getSelectedShop,
      (newValue, oldValue) => {
        if (oldValue !== newValue) {
          this.selectedShop = newValue
        }
      }
    )

    // Watcher when the full url change
    this.$store.watch(
      (state, getters) => getters.getFullUrl,
      (newValue, oldValue) => {
        if (oldValue !== newValue) {
          this.fullUrl = newValue
        }
      }
    )

    // Watcher when the lang change
    this.$store.watch(
      (state, getters) => getters.getLang,
      (newValue, oldValue) => {
        if (oldValue !== newValue) {
          this.lang = newValue
          // get the new navigation by the new lang
          this.navigations = this.$store.getters.getNavigation(newValue)
        }
      }
    )

    // watcher when the navigation change
    this.$store.watch(
      (state, getters) => getters.getNavigation(this.lang),
      (newValue, oldValue) => {
        if (oldValue !== newValue) {
          this.navigations = newValue
          this.loadNavigation()
        }
      }
    )

    // set the navigation on the current lang
    this.navigations = this.$store.getters.getNavigation(this.lang)
    // format the url if is need
    this.formatUrl()
    // load  (display) the navigation
    this.loadNavigation()

    // Add event when page load for build the url
    window.addEventListener('beforeunload', this.buildPathUrl)
  },
  mounted () {
    // if we are on the article page
    if (hackQL.is(this.$route.path, 'articles')) {
      // add the class active on ressources (navigation)
      const selectedMain = `/${(this.$i18n.locale === 'fr-CA') ? 'fr' : 'en'}/${hackQL.getPath('ressources')[(this.$i18n.locale === 'fr-CA') ? 1 : 0]}`
      document.querySelectorAll(`.primary-nav__bottom a[href='${selectedMain}']`)[0].className = 'router-link-exact-active router-link-active'
    }
  }
}
