<template>
  <footer>
    <div class="row no-gutters footer-top text-center">
      <nav class="primary-nav col-sm-12 col-mdpi-6 footer-top-left block-align order-2 order-mdpi-1" >
        <div
          v-for="top in topFooter"
          v-bind:key="top.entity_id"
        >
          <b-link
            v-if="top.block_button && top.block_button[0] && isExternal(top.block_button[0].button_link)"
            :href="(top.block_button[0].button_link) ? top.block_button[0].button_link : '#'"
            :target="(top.block_button[0].button_target) ? top.block_button[0].button_target : '_blank'"
            class="top-title" >
            {{ top.page_title }}
          </b-link>
          <b-link
            v-else
            :href="`${getPathUrl()}${top.slug}`"
            class="top-title" >
            {{ top.page_title }}
          </b-link>
          <div
            v-if="top.child_pages && top.child_pages.length > 0"
          >
            <div
              v-for="child in top.child_pages"
              v-bind:key="child.entity_id"
            >
              <b-link
                :href="`${getPathUrl()}${top.slug}/${child.slug}`"
                class="top-item" >
                  {{ child.page_title }}
              </b-link>
            </div>
          </div>
        </div>
      </nav>

      <div class="newsletter-box col-sm-12 col-mdpi-6 footer-top-right block-align order-1 order-mdpi-2" >
        <div class="row no-gutters text-left">
          <div class="col inner">

              <span class="newsletterTitle">
                {{ $t('footer.newsletterTitle') }}
              </span>

              <div>
                <button type="submit" value="" class="btn" @click="openModal()">
                  {{ $t('footer.newsletterBtn') }}
                </button>
              </div>

            </div>
          </div>
      </div>
    </div>

    <div class="row no-gutters footer-bottom no-gutters">
      <div class="copyright col-mdpi order-3 order-mdpi-1" >
        <p v-html="getCopyrightText()"></p>
      </div>

      <nav class="col-mdpi order-1 order-mdpi-2" >
        <div class="social-icons">
          <b-link
            v-for="social in socialMedia"
            v-bind:key="social.entity_id"
            :href="(social.block_button && social.block_button[0] && social.block_button[0].button_link) ? social.block_button[0].button_link : '#'"
            :target="(social.block_button && social.block_button[0] && social.block_button[0].button_target) ? social.block_button[0].button_target : '_self'"
            class="bottom-link" >
              <div v-html="displayLogo(social)" class="icon icon__lg"></div>
          </b-link>
        </div>
      </nav>

      <nav class="legals-nav col-mdpi order-2 order-mdpi-3" >
        <b-link
          v-for="bottom in bottomFooter"
          :key="bottom.entity_id" :to="`${getPathUrl()}${bottom.slug}`"
          class="bottom-link" >
            {{ bottom.page_title }}
        </b-link>
        <b-link
           :href="$t('privacy_link')"
           target="blank"
          class="bottom-link" >
          {{ $t('privacy_text') }}
        </b-link>
      </nav>
    </div>
  </footer>
</template>

<script>
import NewsletterBlock from '../common/NewsletterBlock.vue'
import linkMixing from '../../mixins/link'

export default {
  name: 'Footer',
  mixins: [linkMixing],
  components: {
    NewsletterBlock
  },
  data () {
    return {
      navigations: this.$store.getters.getNavigation(),
      lang: this.$store.getters.getLang,
      socialMedia: [],
      topFooter: [],
      bottomFooter: []
    }
  },
  methods: {
    getCopyrightText () {
      const d = new Date()
      const year = d.getFullYear()
      return this.$t('generalUi.copyright').replace('__year__', year)
    },
    /**
     * Load the footer navigation
     * @returns {Void}
     */
    loadNavigation () {
      // loop on navigation
      this.navigations.forEach((element) => {
        // is for the social media
        if (element.navigation_id === 'social_media') {
          this.socialMedia = this.$JSON5.parse(element.element)
        }
        // is for the footer
        if (element.navigation_id === 'footer') {
          this.topFooter = this.$JSON5.parse(element.element)
        }
        // is for the second footer
        if (element.navigation_id === 'footer_2') {
          this.bottomFooter = this.$JSON5.parse(element.element)
        }
      })
    },
    /**
     * Display the logo
     * @returns {String}
     */
    displayLogo (item) {
      return (item && item.logo && item.logo.file && item.logo.file.html) ? item.logo.file.html : ''
    },
    /**
     * Get the start path with the good lang
     * @returns {String}
     */
    getPathUrl () {
      switch (this.$i18n.locale) {
        case 'fr-CA':
          return '/fr/'
        default:
          return '/en/'
      }
    },
    /**
     * Open the modal
     * @returns {Void}
     */
    openModal () {
      this.$bvModal.show('b2b-modal-newsletter')
    }
  },
  created () {
    // Watcher when the lang change
    this.$store.watch(
      (state, getters) => getters.getLang,
      (newValue, oldValue) => {
        if (oldValue !== newValue) {
          this.lang = newValue
          // Load the navigation with the new lang
          this.navigations = this.$store.getters.getNavigation(newValue)
        }
      }
    )

    // Watcher for the navigation
    this.$store.watch(
      (state, getters) => getters.getNavigation(this.lang),
      (newValue, oldValue) => {
        if (oldValue !== newValue) {
          this.navigations = newValue
          this.loadNavigation()
        }
      }
    )

    // get the current navigation
    this.navigations = this.$store.getters.getNavigation(this.lang)
    // load the navigation
    this.loadNavigation()
  }
}
</script>

<style lang="scss" scoped>

.block-align {
  padding: 3.5rem 0;

  @include breakpoint('mdpi') {
    padding: 8rem 6.5rem 5rem;
  }
}

.primary-nav{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  color: $white;

  @include breakpoint('mdpi') {
    justify-content: flex-start;
  }

  & > div {
    flex: 0 1 100%;
    margin-bottom: 2rem;
    &:last-child{
      margin-bottom: 0;
    }

    @include breakpoint('mdpi') {
      flex: 0 1 auto;
      margin-right: 5vw;
      margin-bottom: 0;
      text-align: left;
      &:last-child{
        margin-right: 0;
      }
    }
  }

  .top-title {
    font-size: 2.1rem;
    color: $white;
    text-decoration: none;
    margin-bottom: 1rem;
    display: inline-block;

    &:last-child{
      margin-bottom: 0;
    }
  }
  .top-item {
    color: currentColor;

  }
}

.footer-top {
  min-height: 227px;
  opacity: 1;
  align-items: stretch;
}

.footer-bottom {
  padding: 4rem 1.5rem 3rem;
  background-color: #F4F4F4;

  @include breakpoint('mdpi'){
    padding: 1rem 6.5rem;
    justify-content: space-between;
    align-items: center;
    min-height: 12rem;
  }
}

.footer-top-left {
  background: $dark-grey-unipro 0% 0% no-repeat padding-box;
}
.footer-top-right {
  background: $white 0% 0% no-repeat padding-box;
}

.social-icons{
  display: flex;
  justify-content: center;
  align-items: center;

  max-width: 50vw;
  margin: 0 auto 6rem;

  @include breakpoint('mdpi'){
    margin: 0 auto;
    max-width: 25rem;
  }

  a {
    color: lighten($black, 50%);
    margin-right: 2rem;

    &:last-child{
      margin-right: 0;
    }

    &:hover{
      color: $black;
    }
  }
}

.legals-nav{
  margin-bottom: 4rem;

  display: flex;
  flex-direction: column;
  text-align: center;

  @include breakpoint('mdpi') {
    margin-bottom: 0;
    flex-direction: row;
    justify-content: flex-end;
  }

  & > a {
    color: lighten($black, 50%);
    margin-bottom: 1rem;

    @include breakpoint('mdpi') {
      margin-bottom: 0;
      margin-right: 3.5rem;

      &:last-child{
        margin-right: 0;
      }
    }
  }
}

.copyright{
  font-size: 1.1rem;
  color: lighten($black, 50%);
  text-align: center;

  p{
    margin-bottom: 0;
  }

  @include breakpoint('mdpi'){
    text-align: left;
  }
}
.newsletter-box{
  padding-left: 3.5rem;
  padding-right: 3.5rem;

  @include breakpoint('lg'){
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .inner{
    text-align: center;
    @include breakpoint('lg'){
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }
  }
}
.newsletterTitle {
  font-size: 2.1rem;
  font-weight: 600;
  letter-spacing: -0.42px;
  color: #202124;
  opacity: 1;

  margin-bottom: 3rem;
  display: block;
}
</style>
